import { motion, useViewportScroll } from 'framer-motion';
import React, { useState } from 'react';

interface HeaderBannerProps {
  content: string;
}

const HeaderBanner = ({ content }: HeaderBannerProps) => {
  const { scrollY } = useViewportScroll();
  const [showBanner, setShowBanner] = useState(scrollY.get() < 30);

  scrollY.onChange((value) => setShowBanner(value < 30));

  return (
    <motion.div
      className="overflow-hidden md:text-center"
      animate={{ height: showBanner ? 'auto' : 0 }}
    >
      <div className="py-4 text-white bg-navy-blue">
        <div className="container flex space-x-4 md:items-center md:justify-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
            className="flex-shrink-0"
          >
            <path
              fill="#DFC2C2"
              d="M12.935 10.546c.16.07.319.165.455.258 1.035.786.228 1.96-.239 2.804-.057.117-.25.4-.296.481-.09.082-.182.164-.25.235a1.817 1.817 0 01-.205.117c-.09-.023-.068-.012 0 0-.011 0-.023.012-.034.012-.114 0-.262 0-.319.012-.102-.024-.307-.059-.34-.07-.012-.012-.035-.024-.058-.036-.068-.047-.148-.094-.227-.14-.035-.036-.194-.177-.217-.212-.113-.117-.227-.234-.33-.364-.011-.023-.034-.058-.08-.129a8.6 8.6 0 01-.318-.551c-.045-.082-.182-.41-.193-.41a7.903 7.903 0 01-.24-.892v-.07c0-.118.012-.259 0-.376-.022-.47.376-.786.774-.892.069-.023.126-.035.183-.047h-.194c.068 0 .148 0 .216-.011.148-.036.182-.024.103-.012.125-.012.261-.024.387-.024.443.024.978.118 1.422.317zM17.77 1.76C15.597.586 13.925-.14 11.365.023c-2.822.188-4.927 1.49-6.998 3.32C2.012 5.42.18 7.99.02 10.933a13.876 13.876 0 001.297 6.629c1.332 2.85 3.528 4.329 6.532 5.384 2.833 1.01 6.133 1.608 8.864.317 2.378-1.126 4.392-3.015 5.484-5.49.102-.235.194-.481.285-.716a13.012 13.012 0 001.388-7.919c-.478-3.12-3.107-5.748-6.1-7.379z"
            ></path>
          </svg>
          <div
            className="u-banner-prose"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
      </div>
    </motion.div>
  );
};

export default HeaderBanner;
