import React from 'react';

const ChevronDownIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="10"
    fill="none"
    viewBox="0 0 14 10"
    {...props}
  >
    <path
      fill="currentColor"
      d="M14 2.21L12.59.8 8 5.38l-1 .83-1-.83L1.41.79 0 2.21l7 7 7-7z"
    ></path>
  </svg>
);

export default ChevronDownIcon;
