import WPSeo from 'gatsby-plugin-wpgraphql-seo';
import React, { PropsWithChildren } from 'react';
import { Helmet } from 'react-helmet';

interface SeoProps {
  title?: String;
  meta?: [];
  post?: any;
  postSchema?: IPageSchema;
  scripts?: string[];
}

interface IPageSchema {
  '@context': string;
  '@graph': IPageSchemaItems[];
}
interface IPageSchemaItems {
  '@type': any;
  '@id': string;
  url: string;
  name: any;
  isPartOf: {
    '@id': string;
  };
  description: any;
  inLanguage: String;
  potentialAction: {
    '@type': string;
    target: string[];
  }[];
  datePublished?: string;
  dateModified?: string;
}

const Seo = ({ children, scripts, ...props }: PropsWithChildren<SeoProps>) => {
  const meta = [
    {
      property: 'og:image',
      content:
        props.post?.seo?.opengraphImage?.sourceUrl || '/images/og-image.jpg',
    },
  ];

  if (props.post?.seo?.twitterImage?.sourceUrl) {
    meta.push({
      property: 'twitter:image',
      content: props.post?.seo?.twitterImage?.sourceUrl,
    });
  }

  return (
    <>
      <WPSeo {...props} />
      <Helmet
        meta={meta}
        htmlAttributes={{ lang: 'en-US' }}
        script={scripts?.map((script) => ({
          type: 'text/javascript',
          innerHTML: script,
        }))}
      >
        {children}
      </Helmet>
    </>
  );
};

export default Seo;
