import { graphql, Link, useStaticQuery } from 'gatsby';
import React from 'react';
import ChevronDownIcon from '../atoms/ChevronDownIcon';

interface NavProps {
  isMenuDropdownOpen: boolean;
  onToggleMenu: (isOpen: boolean) => void;
}

const Nav = ({ isMenuDropdownOpen, onToggleMenu: onToggleMenu }: NavProps) => {
  const {
    wpMenu: {
      menuItems: { nodes },
    },
  } = useStaticQuery(graphql`
    {
      wpMenu(slug: { eq: "header-nav" }) {
        menuItems {
          nodes {
            id
            label
            url
            menu {
              isMenu
            }
          }
        }
      }
    }
  `);

  return (
    <nav className="hidden lg:flex">
      <ul className="flex items-center m-0 space-x-8">
        {nodes.map((node) => (
          <li key={node.id} className="mb-0">
            {node.menu.isMenu ? (
              <button
                type="button"
                className="u-nav-link"
                onClick={() => onToggleMenu(!isMenuDropdownOpen)}
              >
                <span>{node.label}</span>

                <ChevronDownIcon className="w-auto h-2" />
              </button>
            ) : (
              <Link
                to={node.url.replace(/\/$/, '')}
                className="u-nav-link"
                activeClassName="u-nav-link--active"
                partiallyActive
              >
                {node.label}
              </Link>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Nav;
