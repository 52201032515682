import { motion } from 'framer-motion';
import React from 'react';
import Button from '../atoms/Button';

interface MenuDropdownProps {
  cities: Array<{ title: string; slug: string }>;
}

const MenuDropdown = ({ cities }: MenuDropdownProps) => (
  <motion.div
    className="absolute left-0 right-0 h-[168px] top-full flex items-center"
    initial={{ opacity: 0 }}
    animate={{
      opacity: 1,
      transition: { delay: 0.5 },
    }}
    exit={{ opacity: 0 }}
  >
    <div className="container flex items-center justify-between ">
      <motion.div
        className="flex-grow flex-shrink-0"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { delay: 0.6 } }}
        exit={{ opacity: 0 }}
      >
        <h2 className="u-h2">
          Select
          <br />a menu
        </h2>
      </motion.div>
      <div className="flex justify-end lg:pl-24 xl:pl-0 xl:w-[866px]">
        <div className="flex flex-wrap items-center justify-start flex-grow">
          {cities.map(({ slug, title }, index) => (
            <motion.div
              key={slug}
              className="py-3 mx-3"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { delay: 0.6 + 0.1 * index } }}
              exit={{ opacity: 0 }}
            >
              <Button to={`/menu/${slug}`} variant="outline">
                {title}
              </Button>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
    <div className="absolute left-0 right-0 h-0 border-b -top-px border-linen" />
  </motion.div>
);

export default MenuDropdown;
