import { Link } from 'gatsby';
import React, { PropsWithChildren } from 'react';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

interface ButtonProps {
  to?: string;
  type?: 'button' | 'submit';
  variant?: 'default' | 'inverted' | 'outline' | 'pink';
  disabled?: boolean;
  className?: string;
  isActive?: boolean;
  onClick?: () => void;
}

const variantClassMap = {
  default: 'u-btn--default',
  inverted: 'u-btn--inverted',
  outline: 'u-btn--outline',
  pink: 'u-btn--pink',
};

const Button = ({
  to,
  type = 'button',
  variant = 'default',
  isActive,
  disabled,
  className = '',
  onClick,
  children,
}: PropsWithChildren<ButtonProps>) => {
  const isAbsolute = to?.startsWith('http') || to?.startsWith('mailto');
  const classNames = `u-btn ${variantClassMap[variant]} ${
    isActive ? 'u-btn--active' : ''
  } ${className}`;

  const InnerContent = (
    <span className="u-btn-inner">
      {children}
      {variant !== 'pink' && (
        <svg
          width="15"
          height="12"
          viewBox="0 0 15 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="w-3.5 h-auto u-btn-inner-arrow"
        >
          <path
            d="M9 0L7.59 1.41L11.17 5H0V7H11.17L7.58 10.59L9 12L15 6L9 0Z"
            fill="currentColor"
          />
        </svg>
      )}
    </span>
  );

  if (to && isAbsolute) {
    return (
      <OutboundLink
        href={to}
        target="_blank"
        rel="noopener noreferrer"
        className={classNames}
      >
        {InnerContent}
      </OutboundLink>
    );
  }

  if (to && !isAbsolute) {
    return (
      <Link to={to} className={classNames}>
        {InnerContent}
      </Link>
    );
  }

  return (
    <button
      type={type}
      onClick={onClick}
      className={classNames}
      disabled={disabled}
    >
      {InnerContent}
    </button>
  );
};

export default Button;
