import React from 'react';

const FacebookIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    fill="none"
    viewBox="0 0 32 32"
    {...props}
  >
    <path
      fill="#fff"
      d="M32 16c0-8.837-7.163-16-16-16S0 7.163 0 16c0 7.986 5.85 14.605 13.5 15.806V20.625H9.437V16H13.5v-3.525c0-4.01 2.389-6.225 6.043-6.225 1.75 0 3.582.313 3.582.313V10.5h-2.017c-1.988 0-2.608 1.233-2.608 2.5v3h4.438l-.71 4.625H18.5v11.18C26.15 30.606 32 23.986 32 16z"
    ></path>
  </svg>
);

export default FacebookIcon;
