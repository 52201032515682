import { AnimatePresence, motion, useViewportScroll } from 'framer-motion';
import { graphql, Link, useStaticQuery } from 'gatsby';
import React, { useEffect, useRef, useState } from 'react';
import { useLockBodyScroll } from 'react-use';
import { useOnClickOutside } from '../../hooks/use-on-click-outside';
import Button from '../atoms/Button';
import Logo from '../atoms/Logo';
import HeaderBanner from '../molecules/HeaderBanner';
import MenuDropdown from '../molecules/MenuDropdown';
import MobileMenu from '../molecules/MobileMenu';
import Nav from '../molecules/Nav';

interface HeaderProps {
  transparent: boolean;
}

const Header = ({ transparent }: HeaderProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const { scrollY } = useViewportScroll();
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [hasBackground, setBackground] = useState(
    !transparent || scrollY.get() > 30
  );
  const [isAbsolute, setAbsolute] = useState(false);
  const [isMenuDropdownOpen, setMenuDropdownOpen] = useState(false);
  const {
    allWpCity: { nodes: cities },
    wp: {
      bannerSettings: { bannerSettings },
    },
  } = useStaticQuery(graphql`
    query Cities {
      allWpCity(sort: { fields: title }) {
        nodes {
          title
          slug
        }
      }
      wp {
        bannerSettings {
          bannerSettings {
            visible
            content
          }
        }
      }
    }
  `);

  useOnClickOutside(ref, () => {
    setMenuDropdownOpen(false);
  });

  scrollY.onChange((value) => {
    if (!transparent) {
      return;
    }

    if (!hasBackground && value > 30) {
      setBackground(true);
      return;
    }

    if (hasBackground && value <= 30) {
      setBackground(false);
    }
  });

  useEffect(() => {
    if (!isAbsolute && isMobileMenuOpen) {
      setAbsolute(true);
      return;
    }

    if (isAbsolute && !isMobileMenuOpen) {
      setTimeout(() => setAbsolute(false), 300);
    }
  }, [isMobileMenuOpen]);

  const isWhiteBackground = hasBackground || isMenuDropdownOpen;

  useLockBodyScroll(isMenuDropdownOpen);

  return (
    <header
      ref={ref}
      className={`fixed top-0 left-0 right-0 z-[60] bg-transparent ${
        isWhiteBackground ? 'is-white' : 'is-transparent'
      }`}
    >
      {bannerSettings.visible && (
        <HeaderBanner content={bannerSettings.content} />
      )}
      <AnimatePresence exitBeforeEnter>
        {isMenuDropdownOpen && <MenuDropdown cities={cities} />}
      </AnimatePresence>
      <motion.div
        className="absolute top-0 left-0 right-0 transition-all bg-white -z-1 shadow-card"
        animate={{
          height: isMenuDropdownOpen ? (bannerSettings.visible ? 316 : 260) : 0,
        }}
        transition={{ ease: 'easeInOut' }}
      />
      {!transparent ? (
        <div className="absolute top-0 left-0 right-0 h-full transition-all bg-white border-b -z-1 border-linen" />
      ) : (
        <motion.div
          className="absolute top-0 left-0 right-0 transition-all bg-white border-b -z-1 border-linen"
          animate={{
            height: hasBackground ? '100%' : 0,
          }}
          transition={{ ease: 'easeInOut' }}
        />
      )}
      <motion.div
        className="absolute top-0 left-0 right-0 pointer-events-none -z-1 h-52 bg-fade-gradient"
        style={{ opacity: isWhiteBackground ? 0 : 0.3 }}
      />
      <AnimatePresence>
        {isMobileMenuOpen && (
          <MobileMenu
            isMenuDropdownOpen={isMenuDropdownOpen}
            onToggleMenu={setMenuDropdownOpen}
          />
        )}
      </AnimatePresence>
      <div
        className={`container flex items-center justify-between py-6 ${
          isAbsolute && 'absolute top-0 right-0 left-0'
        }`}
      >
        <Link to="/" className="mr-8 u-ring" aria-label="Salty Donut">
          <Logo
            className={`w-auto h-9 ${
              isWhiteBackground || isAbsolute ? 'text-navy-blue' : 'text-white'
            }`}
          />
        </Link>
        <div
          className={`flex items-center mr-1 lg:mr-0 space-x-6 xl:space-x-8 ${
            isWhiteBackground ? 'text-black' : 'text-white'
          }`}
        >
          <Nav
            isMenuDropdownOpen={isMenuDropdownOpen}
            onToggleMenu={setMenuDropdownOpen}
          />
          <Button
            to="https://order.saltydonut.com/"
            variant={isWhiteBackground || isAbsolute ? 'default' : 'inverted'}
          >
            Order<span className="hidden sm:inline"> now</span>
          </Button>
          <button
            type="button"
            className="w-[22px] h-4 relative block lg:hidden"
            aria-label="Open menu"
            onClick={() => setMobileMenuOpen((open) => !open)}
          >
            <span
              className={`absolute transition-all top-0 left-0 right-0 h-0.5 ${
                isWhiteBackground || isMobileMenuOpen
                  ? 'bg-navy-blue'
                  : 'bg-white'
              } ${isMobileMenuOpen && 'transform rotate-45 translate-y-2'}`}
            />
            <span
              className={`absolute transition-all left-0 right-0 h-0.5 ${
                isWhiteBackground || isMobileMenuOpen
                  ? 'bg-navy-blue'
                  : 'bg-white'
              } top-1/2 ${isMobileMenuOpen && 'opacity-0'}`}
            />
            <span
              className={`absolute transition-all left-0 right-0 h-0.5 ${
                isWhiteBackground || isMobileMenuOpen
                  ? 'bg-navy-blue'
                  : 'bg-white'
              } top-full ${
                isMobileMenuOpen && 'transform -rotate-45 -translate-y-2'
              }`}
            />
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
