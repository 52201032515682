import React from 'react';
import FooterBg from '../../images/footer-bg.svg';
import AppStoreIcon from '../atoms/AppStoreIcon';
import Button from '../atoms/Button';
import FacebookIcon from '../atoms/FacebookIcon';
import GooglePlayIcon from '../atoms/GooglePlayIcon';
import InstagramIcon from '../atoms/InstagramIcon';
import Logo from '../atoms/Logo';
import TwitterIcon from '../atoms/TwitterIcon';
import FooterNav from '../molecules/FooterNav';

const Footer = () => (
  <footer className="relative pt-10 text-white pb-60 lg:pb-64 lg:pt-24 bg-navy-blue">
    <div className="container">
      <div className="flex flex-col space-y-6 lg:space-y-0 lg:space-x-8 lg:flex-row">
        <div className="flex lg:block items-center justify-between lg:pr-6 pt-3.5">
          <Logo className="w-auto h-16 mb-5" />
          <Button to="https://order.saltydonut.com/" variant="inverted">
            Order Now
          </Button>
        </div>
        <FooterNav />
        <div className="flex flex-col items-center justify-end flex-grow md:items-start lg:flex-row lg:items-start">
          <div className="lg:max-w-xs lg:block">
            <p className="mb-6 text-left text-white lg:text-right u-p">
              Download The Salty app to order ahead and receive exclusive perks
              + rewards!
            </p>

            <div className="flex items-center justify-start space-x-4 lg:space-x-0 xl:space-x-4 lg:space-y-4 xl:space-y-0 lg:justify-end lg:flex-col xl:flex-row lg:items-end xl:items-center">
              <a
                href="https://apps.apple.com/us/app/salty-donut/id1599787786"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Apple App Store"
                className="relative"
              >
                <AppStoreIcon className="w-auto h-11" />

                <div
                  className="absolute transition-all origin-center transform left-1/2 top-1/2 hover:scale-105"
                  style={{ marginTop: '-22px', marginLeft: '-50%' }}
                >
                  <AppStoreIcon className="w-auto h-11" />
                </div>
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.thesaltydonut.app"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Google Play Store"
                className="relative"
              >
                <GooglePlayIcon className="w-auto h-11" />

                <div
                  className="absolute transition-all origin-center transform left-1/2 top-1/2 hover:scale-105"
                  style={{ marginTop: '-22px', marginLeft: '-50%' }}
                >
                  <GooglePlayIcon className="w-auto h-11" />
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="border-t border-[#c4c4c4] border-opacity-30 pt-8 lg:pt-6 flex flex-col-reverse lg:flex-row lg:items-center justify-between mt-8 lg:mt-12">
        <div className="pt-8 mb-6 lg:mb-0 lg:pt-0">
          All Material © 2022 The Salty Donut, All Rights Reserved
        </div>
        <div className="flex items-center justify-between pb-8 space-x-8 lg:justify-start lg:pb-0 border-b lg:border-0 border-[#c4c4c4] border-opacity-30">
          <div>Follow Us</div>
          <div className="flex items-center space-x-8">
            <a
              href="https://www.instagram.com/thesaltydonut/"
              target="_blank"
              rel="noopener noreferrer"
              className="transition-transform transform hover:-translate-y-1"
              aria-label="Instagram"
            >
              <InstagramIcon className="w-8 h-auto" />
            </a>
            <a
              href="https://www.facebook.com/TheSaltyDonut/"
              target="_blank"
              rel="noopener noreferrer"
              className="transition-transform transform hover:-translate-y-1"
              aria-label="Facebook"
            >
              <FacebookIcon className="w-8 h-auto" />
            </a>
            <a
              href="https://twitter.com/thesaltydonut"
              target="_blank"
              rel="noopener noreferrer"
              className="transition-transform transform hover:-translate-y-1"
              aria-label="Twitter"
            >
              <TwitterIcon className="w-8 h-auto" />
            </a>
          </div>
        </div>
      </div>
    </div>

    <div
      className="absolute bottom-0 left-0 right-0 bg-cover h-52"
      style={{ backgroundImage: `url(${FooterBg})` }}
    />
  </footer>
);

export default Footer;
