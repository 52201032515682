import React from 'react';

const TwitterIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="32"
    height="26"
    viewBox="0 0 32 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.0669 26.001C22.1394 26.001 28.7444 15.9966 28.7444 7.32346C28.7444 7.04221 28.7381 6.75471 28.7256 6.47346C30.0105 5.54426 31.1193 4.39332 32 3.07471C30.8034 3.60711 29.5329 3.95482 28.2319 4.10596C29.6017 3.28485 30.6274 1.99494 31.1187 0.475332C29.8301 1.23905 28.4208 1.77779 26.9513 2.06846C25.9611 1.01639 24.652 0.319798 23.2262 0.0863779C21.8005 -0.147043 20.3376 0.0957104 19.0637 0.777105C17.7897 1.4585 16.7757 2.54058 16.1785 3.85606C15.5812 5.17153 15.4339 6.64713 15.7594 8.05471C13.15 7.92376 10.5972 7.24591 8.26664 6.06511C5.93604 4.8843 3.87959 3.22689 2.23062 1.20033C1.39253 2.6453 1.13608 4.35517 1.51337 5.98243C1.89067 7.60969 2.87342 9.03224 4.26188 9.96096C3.2195 9.92786 2.19997 9.64722 1.2875 9.14221V9.22346C1.28657 10.7398 1.8108 12.2098 2.77108 13.3833C3.73136 14.5569 5.06843 15.3617 6.555 15.661C5.58941 15.9251 4.57598 15.9636 3.59313 15.7735C4.01261 17.0776 4.82876 18.2182 5.92769 19.0361C7.02662 19.854 8.35349 20.3084 9.72313 20.336C7.3979 22.1625 4.52557 23.1531 1.56875 23.1485C1.04438 23.1477 0.520532 23.1155 0 23.0522C3.00381 24.9793 6.49804 26.0028 10.0669 26.001Z"
      fill="white"
    />
  </svg>
);

export default TwitterIcon;
