import { motion, Variants } from 'framer-motion';
import { graphql, Link, useStaticQuery } from 'gatsby';
import React from 'react';
import { useLockBodyScroll } from 'react-use';
import MobileNavWaves from '../../images/mobile-nav-waves.svg';
import ChevronDownIcon from '../atoms/ChevronDownIcon';

const delayedFadeInVariants: Variants = {
  off: {
    opacity: 0,
  },
  on: {
    opacity: 1,
    transition: {
      delay: 0.3,
    },
  },
};

const containerVariants: Variants = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.07,
    },
  },
};

const itemVariants = {
  hidden: { opacity: 0, scale: 1.15, y: -30 },
  show: {
    opacity: 1,
    scale: 1,
    y: 0,
    transition: { type: 'ease', ease: [0.4, 0.01, 0.165, 0.99] },
  },
};

interface MobileMenuProps {
  isMenuDropdownOpen: boolean;
  onToggleMenu: (isOpen: boolean) => void;
}

const MobileMenu = ({ isMenuDropdownOpen, onToggleMenu }: MobileMenuProps) => {
  const {
    wpMenu: {
      menuItems: { nodes },
    },
    allWpCity: { nodes: cities },
  } = useStaticQuery(graphql`
    {
      wpMenu(slug: { eq: "header-nav" }) {
        menuItems {
          nodes {
            id
            label
            url
            menu {
              isMenu
            }
          }
        }
      }
      allWpCity(sort: { fields: title }) {
        nodes {
          title
          slug
        }
      }
    }
  `);

  useLockBodyScroll();

  return (
    <motion.nav
      className="lg:hidden fixed top-0 pt-[92px] left-0 right-0 bg-white overflow-y-auto flex flex-col"
      initial={{ height: 0 }}
      animate={{ height: '100vh' }}
      exit={{ height: 0 }}
      transition={{ type: 'tween' }}
    >
      <div className="flex flex-col flex-grow overflow-y-auto">
        <motion.ul
          className="flex-grow px-5 mb-5"
          variants={containerVariants}
          initial="hidden"
          animate="show"
        >
          {nodes.map((node) => (
            <motion.li
              key={node.id}
              className="py-5 mb-0 text-sm font-medium uppercase border-b border-linen last:border-0"
              variants={itemVariants}
            >
              {node.menu.isMenu ? (
                <>
                  <button
                    type="button"
                    className="flex items-center justify-between w-full text-sm font-medium uppercase"
                    onClick={() => onToggleMenu(!isMenuDropdownOpen)}
                  >
                    <span>{node.label}</span>

                    <ChevronDownIcon
                      className={`w-auto h-2 transition-transform ${
                        isMenuDropdownOpen ? 'rotate-180' : 'roate-0'
                      }`}
                    />
                  </button>
                  <motion.div
                    className="overflow-hidden"
                    animate={{ height: isMenuDropdownOpen ? 'auto' : 0 }}
                    transition={{ type: 'ease' }}
                  >
                    <div className="px-5 py-1 mt-5 bg-linen">
                      {cities.map(({ title, slug }, index) => (
                        <div
                          key={slug}
                          className={`py-4 normal-case ${
                            index < cities.length - 1 && 'border-b'
                          } border-white`}
                        >
                          <Link to={`/menu/${slug}`}>{title}</Link>
                        </div>
                      ))}
                    </div>
                  </motion.div>
                </>
              ) : (
                <Link to={node.url}>{node.label}</Link>
              )}
            </motion.li>
          ))}
        </motion.ul>
        <motion.div
          className="w-full h-16 bg-cover"
          variants={delayedFadeInVariants}
          initial="off"
          animate="on"
          exit="off"
          style={{
            backgroundImage: `url(${MobileNavWaves})`,
          }}
        />
      </div>
    </motion.nav>
  );
};

export default MobileMenu;
