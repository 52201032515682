import React, { PropsWithChildren } from 'react';
import Footer from '../organisms/Footer';
import Header from '../organisms/Header';

interface LayoutProps {
  transparentHeader?: boolean;
}

const Layout = ({
  transparentHeader = false,
  children,
}: PropsWithChildren<LayoutProps>) => (
  <div className="font-sans antialiased">
    <Header transparent={transparentHeader} />
    <main>{children}</main>
    <Footer />
  </div>
);

export default Layout;
