import { graphql, Link, useStaticQuery } from 'gatsby';
import React from 'react';

const FooterNav = () => {
  const {
    wpMenu: {
      menuItems: { nodes },
    },
  } = useStaticQuery(graphql`
    {
      wpMenu(slug: { eq: "footer-nav" }) {
        menuItems {
          nodes {
            id
            label
            url
          }
        }
      }
    }
  `);

  return (
    <ul className="flex-shrink-0 gap-8 pt-4 m-0 border-t border-white md:columns-3 lg:pt-0 lg:border-0 border-opacity-20">
      {nodes.map((node, index) => (
        <li
          key={node.id}
          className={`pb-4 ${
            index === nodes.length - 1 ? 'lg:mb-4' : 'mb-4'
          } border-b ${
            (index + 1) % 3 !== 0 ? 'md:border-b' : 'md:border-0'
          } border-white border-opacity-20`}
        >
          <Link
            to={node.url.replace(/\/$/, '')}
            className="transition hover:text-alto focus-visible:text-alto"
          >
            {node.label}
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default FooterNav;
